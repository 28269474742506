
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  import {State, Getter, Action, Mutation, namespace} from 'vuex-class'

  @Component({
    name: "djhHeaderNav9",
  })
  export default class djhHeaderNav9 extends Vue {
    @State(state => state.web.distributorsObject) distributorsObject!: any;
    @Prop(String) bgColorOut?: string;
    @Prop(String) fontColor?: string;
    @Prop(String) hoverItemBgColor?: string;
    private navActiveIndex:number = 0;
    public num:number = 0;
    public openUrl:any;
    public content:any;
    mounted() {
      console.log(this.distributorsObject.treeMenuButMap['pc1'],'树形菜单-----------------------------------------------')
    }


    navMouseLeave() {
      this.navActiveIndex = 0
    }

    navMouseEnter(index:number) {
        this.navActiveIndex = index
    }
    clickOpen(content:any){
      if(content.id == 111 || content.id == 160 || content.id == 161){
        this.openUrl(content.htUrl,'_blank')
      }
    }

    goToDownloadApp(e:any) {
      window.open('/specilPage/downloadApp', '_blank')
    }


  }
